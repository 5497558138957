<template>
  <div class="detail-container">
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>退货详情</template>
    </breadcrumb-nav>
    <el-card shadow="never">
      <span class="font-title-medium">退货商品</span>
      <el-table
        border
        class="standard-margin"
        ref="goodsTable"
        :data="goodsList">
        <el-table-column label="商品图片" width="160" align="center">
          <template slot-scope="scope">
            <img style="height:80px" :src="scope.row.image">
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center">
          <template slot-scope="scope">
            <span class="font-small">{{scope.row.goodsName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="单价" width="180" align="center">
          <template slot-scope="scope">
            <span class="font-small">￥{{scope.row.price}}</span>
          </template>
        </el-table-column>
        <el-table-column label="规格" width="180" align="center">
          <template slot-scope="scope">{{scope.row.skuDesc}}</template>
        </el-table-column>
        <el-table-column label="申请退货数量" align="center">
          <template slot-scope="scope">{{scope.row.applyNum}}</template>
        </el-table-column>
        <el-table-column label="申请退款金额"  align="center">
          <!--<template>￥{{totalAmount}}</template>-->
          <template slot-scope="scope">￥{{scope.row.applyPrice}}</template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">退款单信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">退款单号</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.refundId}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请状态</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{refundItem.status | formatStatus}}</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small" style="height:50px;line-height:30px">订单编号
          </el-col>
          <el-col class="form-border font-small" :span="18" style="height:50px">
            {{refundItem.orderSn}}
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请时间</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.createTime | formatDate}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">联系人</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.refundUser}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">联系电话</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.refundMobile}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">退货类型</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.refundType}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">退货原因</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.reason}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">问题描述</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.desc}}</el-col>
        </el-row>
        <!--<el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:100px;line-height:80px">凭证图片
          </el-col>
          <el-col class="form-border font-small" :span="18" style="height:100px">
            <img v-for="item in proofPics" v-bind:key="item" style="width:80px;height:80px" :src="item"/>
          </el-col>
        </el-row>-->
      </div>
      <div class="form-container-border">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请退款金额</el-col>
          <el-col class="form-border font-small" :span="18">￥{{refundItem.applyPrice}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">确认退款金额
          </el-col>
          <el-col class="form-border font-small" style="height:52px" :span="18">
            ￥
            <el-input size="small" v-model="updateParam.agreePrice"
                      :disabled="refundItem.status!==1"
                      style="width:200px;margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="refundItem.status!==1">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核人员</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.auditUser || '无'}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核时间</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.auditTime | formatDate}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.auditNote || '无'}}</el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="refundItem.status===3">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">收货人员</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.recUser}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" >收货时间</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.recTime | formatDate}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">收货、退款备注</el-col>
          <el-col class="form-border font-small" :span="18">{{refundItem.recNote}}</el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="refundItem.status===1">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input  size="small" v-model="updateParam.auditNote" style="width:200px;margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="refundItem.status===2">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">收货、退款备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input  size="small" v-model="updateParam.recNote" style="width:200px;margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="refundItem.status===1">
        <el-button type="primary" size="small" @click="handleUpdateStatus(2)">确认退货</el-button>
        <el-button type="danger" size="small" @click="handleUpdateStatus(4)">拒绝退货</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="refundItem.status===2">
        <el-button type="primary" size="small" @click="handleUpdateStatus(3)">确认收货</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="refundItem.status===3 || refundItem.status===4 || refundItem.status===5">
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { GetApplyDetailRequest, UpdateRefundRequest } from '../../../network/refund'
const defaultUpdateParam = {
  status: 0,
  auditUser: '',
  recUser: '',
  auditNote: '',
  recNote: '',
  agreePrice: 0,
  refundId: 0
}
export default {
  name: 'RefundDetail',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      if (value === 1) {
        return '待审核'
      } else if (value === 2) {
        return '退货中'
      } else if (value === 3) {
        return '已退款'
      } else if (value === 4) {
        return '已拒绝'
      } else if (value === 5) {
        return '已取消'
      } else {
        return '未知'
      }
    },
    formatDate (value) {
      if (value === 0) {
        return '无'
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
  },
  data () {
    return {
      refundId: 0,
      goodsList: [],
      refundItem: {},
      downloadUrlPrefix: '',
      updateParam: Object.assign({}, defaultUpdateParam)
    }
  },
  created () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.refundId = this.$route.query.refundId
    this.updateParam.refundId = this.refundId
    this.getRefundDetail()
  },
  methods: {
    getRefundDetail () {
      GetApplyDetailRequest({ refundId: this.refundId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取退货申请列表失败', 'error')
        }
        this.refundItem = result.data
        console.log('this.refund item:', this.refundItem)
        if (this.refundItem.desc === '') {
          this.refundItem.desc = '无'
        }
        // 退货中和完成
        if (this.refundItem.status === 2 || this.refundItem.status === 3) {
          this.updateParam.agreePrice = this.refundItem.agreePrice
        }
        // 待处理, 退款金额设置默认值为申请金额
        if (this.refundItem.status === 1) {
          this.updateParam.agreePrice = this.refundItem.applyPrice
        }
        this.updateParam.auditUser = this.refundItem.auditUser
        this.updateParam.auditNote = this.refundItem.auditNote
        this.updateParam.recUser = this.refundItem.recUser
        this.updateParam.recNote = this.refundItem.recNote
        this.goodsList = []
        this.goodsList.push({
          image: this.downloadUrlPrefix + this.refundItem.icon,
          goodsName: this.refundItem.goodsName,
          skuDesc: this.refundItem.skuDesc,
          price: this.refundItem.price,
          applyNum: this.refundItem.applyNum,
          applyPrice: this.refundItem.applyPrice
        })
        console.log('refund goodsList:', this.goodsList)
      })
    },
    handleUpdateStatus (status) {
      this.updateParam.status = status
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      console.log('handleUpdateStatus userInfo:', userInfo.name)
      if (status === 2 || status === 4) {
        this.updateParam.auditUser = userInfo.name
        if (this.updateParam.auditNote === '') {
          return this.alertMessage('请输入审核备注', 'error')
        }
      }
      if (status === 3) {
        this.updateParam.recUser = userInfo.name
        if (this.updateParam.recNote === '') {
          return this.alertMessage('请输入收货备注', 'error')
        }
      }
      this.updateParam.refundId = parseInt(this.updateParam.refundId)
      console.log('updateParam:', this.updateParam)
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        UpdateRefundRequest(this.updateParam).then(res => {
          console.log('handleUpdateStatus res:', res.data)
          this.alertMessage('操作成功', 'success')
          this.$router.back()
        })
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.detail-container {
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  margin-top: 15px;
}

.form-left-bg {
  background: #F2F6FC;
}
.form-color {
  color: red;
}
</style>
